//MODULES
@import './modules/mixins';
@import './admin/_variables';

body{
  background-color: $secondary;
}

.auth{
  .card{
    .card-header{
      button{
        color: $secondary;
      }

      h4{
        text-align: center;
        margin: 0;
        color: $gray;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
      }
    }
  }

  .login-brand{
    margin: 24% 0 0 0;

    img{
      height: 150px;
      margin: 20px 0;
      padding: 0;
    }
  }
}