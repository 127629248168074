@mixin backgroundShort($imgPath, $xPosition, $yPosition, $imgRepeat, $imgSize) {
	background: {
		image: url($imgPath);
		position: $xPosition $yPosition;
		repeat: $imgRepeat;
		size: $imgSize;
	}
}

@mixin borderRadius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}

@mixin placeholderColor($color) {
	&::-webkit-input-placeholder {color: $color;}
	&:-moz-placeholder {color: $color;}
	&::-moz-placeholder {color: $color;}
	&:-ms-input-placeholder {color: $color;}
}

@mixin radialGradient($from, $to) {
	background: -moz-radial-gradient(center, circle cover, $from 0%, $to 100%);
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $from), color-stop(100%, $to));
	background: -webkit-radial-gradient(center, circle cover, $from 0%, $to 100%);
	background: -o-radial-gradient(center, circle cover, $from 0%, $to 100%);
	background: -ms-radial-gradient(center, circle cover, $from 0%, $to 100%);
	background: radial-gradient(center, circle cover, $from 0%, $to 100%);
	background-color: $from;
}

@mixin transition($transitionArgs) {
	-webkit-transition: $transitionArgs;
	-moz-transition: $transitionArgs;
	-ms-transition: $transitionArgs;
	-o-transition: $transitionArgs;
	transition: $transitionArgs;
}

@function calculateRem($size) {
	$remSize: $size / 16px;
	@return $remSize * 1rem;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}