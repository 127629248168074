//Typography
$font-family-sans-serif: 'Nunito', sans-serif;

$primary: #6C17B3;
$primaryHover: #540b8f;

$secondary: #3F3F3F;
$secondaryHover: #3F3F3F;

$gray: #565C65;

$black: #212121;
$white: #FFFFFF;

a{
  color: $primary;
}

a, .btn{
  &:hover{
    cursor: pointer;
  }
}

.bg-primary{
  background-color: $primary !important;
}

.btn-primary{
  @include transition(.5s ease all);
  background-color: $primary !important;
  border: 1px solid $primary !important;

  &:hover{
    background-color: $primaryHover !important;
    border: 1px solid $primaryHover !important;
  }
}

.card{
  &.card-primary{
    border-top: 2px solid $primary;
  }

  .card-header{
    .btn-right{
      position: absolute;
      float: right;
      right: 15px;
    }

    h4{
      color: $primary;
    }
  }
}

.text-primary{
  color: $primary !important;
}